import React from "react";

function ChevronIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="#e39b10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9 1L5 5 1 1"
      ></path>
    </svg>
  );
}

export default ChevronIcon;